/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroDefault from 'components/elements/HeroDefault'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import GravityForm from 'components/shared/GravityForm'

// Third Party
import styled from 'styled-components'

const Title = styled.h2`
  font-size: ${props => props.theme.font.size.xxml};

  span {
    padding-left: 20px;
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.m};
  }
`

const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} article />
      
      <HeroDefault
        contact={acf.info}
        statement="Prof & Prof en Recht is Rond zijn als merknaam (trademark) geregistreerd bij het Benelux-Bureau voor de Intellectuele Eigendom (BOIP Merkenregister) onder nr. 1419752 resp. 1419830."
        home
        fields={acf.banner}
      />

      <div style={{ paddingBottom: '150px', backgroundColor: '#F2F2F2' }}>
        <div className="container py-lg-5 py-3">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <Title className="mb-4">Contact</Title>
              <GravityForm bigTextArea submitStyle="white" id={1} />
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...contactPageFragment
    }
  }
`

export default PageTemplate
